import React, { useMemo } from "react";

import { useIdContext } from "@id/context";

import { Status, useIdLeads } from "@api/id";

import { spinnerStyles, useStyles } from "@id/styles";

import { IdCard } from "@id/IdCard";

import Box from "@material-ui/core/Box";

import { Spinner } from "~/components/Spinner";
import Typography from "@material-ui/core/Typography";
import { PAGE_SIZE_VERIFIED } from "@id/constants";
import Pager from "@id/Pager";
import { Grid } from "@material-ui/core";
import { useSearch } from "~/components/Search";


export const IdVerifiedDisplay: React.FC = () => {
    const { search } = useSearch();
    const { companyId, page } = useIdContext();
    const styles = useStyles();
    const { data, error, loading, count } = useIdLeads({ companyId, status: Status.COMPLETED, limit: PAGE_SIZE_VERIFIED, offset: (page - 1) * PAGE_SIZE_VERIFIED, search });

    const content = useMemo(() => {
        if (loading) {
            return <Spinner style={spinnerStyles} />;
        }

        if (!data.length) {
            return <Typography className={styles.emptyVerifiedIds} variant="caption">Ooops! Still no verified IDs &#x1F601;</Typography>;
        }

        return (
            <Box className={styles.contentContainer}>
                <Grid container spacing={2}>
                    {data.map((lead: any) => {
                        return (
                            <IdCard
                                leadId={lead?.id}
                                key={lead?.id}
                                issuer={lead?.retrieveIdVerification?.fields?.idIssuer?.value}
                                firstName={lead?.firstName}
                                lastName={lead?.lastName}
                                dateOfBirth={lead?.dateOfBirth}
                                country={lead?.country}
                                street={lead?.street}
                                streetNumber={lead?.streetNumber}
                                postalCode={lead?.postalCode}
                                provinceCode={lead?.provinceCode}
                                city={lead?.city}
                                action={lead?.retrieveIdVerification?.action}
                            />
                        );
                    })}
                </Grid>
            </Box>
        );
    }, [data, loading, styles.contentContainer, styles.emptyVerifiedIds]);

    const limit = PAGE_SIZE_VERIFIED;

    return (
        <Box
            display="flex"
            justifyContent={loading || error || !data?.length ? "center" : ""}
            flexDirection="column"
            flexWrap="nowrap"
            height="100%"
        >
            {content}
            <Pager count={count} limit={limit} />
        </Box>
    );
};