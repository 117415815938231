import { startCase, toLower } from "lodash";

export const formatAction = (action: string) => {
    if (!action) {
        return "Undetermined";
    }

    switch (action) {
        case "reject":
            return "Rejected";
        case "review":
            return "Needs Review";
        case "accept":
            return "Accepted";
        default:
            return "Processing";
    }
};

export const formatFlag = (flag: string) => {
    return startCase(toLower(flag?.replace("id_", "")));
};

export const formatDocument = (type: string) => {
    switch (type?.toLowerCase()) {
        case "dl":
            return "Drivers License";
        case "id":
            return "Identification Card";
        case "passport":
            return "Passport";
        default:
            return startCase(toLower(type));
    }
};

export const formatIssuer = (issuer: string) => {
    switch (issuer) {
        case "CA-AB":
            return "Alberta";
        case "CA-BC":
            return "British Columbia";
        case "CA-MB":
            return "Manitoba";
        case "CA-NB":
            return "New Brunswick";
        case "CA-NL":
            return "Newfoundland and Labrador";
        case "CA-NT":
            return "Northwest Territories";
        case "CA-NS":
            return "Nova Scotia";
        case "CA-NU":
            return "Nunavut";
        case "CA-ON":
            return "Ontario";
        case "CA-PE":
            return "Prince Edward Island";
        case "CA-QC":
            return "Quebec";
        case "CA-SK":
            return "Saskatchewan";
        case "CA-YT":
            return "Yukon";
        default:
            return issuer;
    }
};