import React from "react";

import { useAuth } from "@api/auth";

import { isProductEnabled } from "@utils/products";

import { createIdView, ViewComponent } from "../_lib/view";
import { IdProvider } from "@id/context";
import { IdVerifiedDisplay } from "./_lib/IdVerifiedDisplay";

import { ProductType } from "@api/products";
import { Redirect } from "~/components/Redirect";

const VerifiedView: ViewComponent = () => {
    const { selectedCompany } = useAuth();

    const isIdToolEnabled = isProductEnabled(selectedCompany, ProductType.IdTool);
    if (!isIdToolEnabled) {
        return <Redirect to="/id/" />;
    }

    return (
        <IdProvider>
            <IdVerifiedDisplay />
        </IdProvider>
    );
};

export default createIdView(VerifiedView, {
    title: "Verified",
    internalPad: true,
    internalScroll: true,
});