import React from "react";

import { navigate } from "@utils/navigate";

import { formatAction } from "@utils/id";

import type { VerifiedIdCardProps } from "@id/types";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import  Typography  from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";

import DriverLicense from "~/assets/vectors/drivers-license-placeholder.svg";

const defaultRow = {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "baseline",
    gap: 36,
};

export const status = (action: string) => {
    const background = (action: string) => {
        switch (action) {
            case "reject":
                return "#f44336";
            case "review":
                return "#003049";
            case "accept":
                return "#4caf50";
            default:
                return "#003049";
        }
    };

    return (
        <Chip
            style={{ backgroundColor: background(action), color: "white", fontFamily: "inherit", fontSize: "inherit", fontWeight: "bold" }}
            label={formatAction(action)}
        />
    );
};



const useStyles = makeStyles(() => {
    return {
        cardWrap: {
            backgroundColor: "#F5F5F7",
            alignSelf: "flex-start",
            borderRadius: 14,
            cursor: "pointer",
            width: 300,
            height: 425,
        },
        cardSummary: {
            display: "flex",
            flexDirection: "column",
            gap: 12,
            padding: "12px 18px",
            maxWidth: 320,
        },
        cardRow: {
            ...defaultRow,
        },
        cardColumn: {
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: 4,
        },
        cardTitle: {
            fontWeight: "bold",
        },
        cardVerified: {
            ...defaultRow,
            gap: 12,
        },
        photo: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        imagePlaceholder: {
            width: "100%",
            height: 150,
        },
        address: {
            textDecoration: "underline",
        },
    };
});

export const IdCard: React.FC<VerifiedIdCardProps> = ({ issuer, dateOfBirth, firstName, lastName, country, provinceCode, streetNumber, street, city, postalCode, action, leadId }) => {
    const styles = useStyles();
    const address = [streetNumber, street, city, provinceCode, postalCode, country].filter(Boolean).join(" ");
    // const issuer = [country, provinceCode].filter(Boolean).join("-");
    return (
        <Grid item>
            <Box onClick={() => navigate(`/lead/${leadId}`)} className={styles.cardWrap}>
                <Box>
                    <DriverLicense className={styles.imagePlaceholder} />
                </Box>
                <Box className={styles.cardSummary}>
                    <Box className={styles.cardVerified}>
                        <Typography className={styles.cardTitle}>{`${firstName} ${lastName}`}</Typography>
                        {status(action)}
                    </Box>
                    <Box className={styles.cardRow}>
                        <Box className={styles.cardColumn}>
                            <Typography className={styles.cardTitle}>Date Of Birth</Typography>
                            <Typography>{dateOfBirth}</Typography>
                        </Box>
                        {!!issuer && <Box className={styles.cardColumn}>
                            <Typography className={styles.cardTitle}>ID Issuer</Typography>
                            <Typography>{issuer}</Typography>
                        </Box>}
                    </Box>
                    <Box className={styles.cardRow}>
                        <Box className={styles.cardColumn}>
                            <Typography className={styles.cardTitle}>ID Type</Typography>
                            <Typography>DL</Typography>
                        </Box>
                        {!!address && <Box className={styles.cardColumn}>
                            <Typography className={styles.cardTitle}>Address:</Typography>
                            <Typography className={styles.address}>{address}</Typography>
                        </Box>}
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};