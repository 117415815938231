import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles(() => ({
    contentContainer: {
        display: "flex",
        flexFlow: "row nowrap",
        height: "100%",
        overflowY: "scroll",
        padding: 18,
    },
    emptyVerifiedIds: {
        fontSize: 24,
        alignSelf: "center",
    },
}));

export const spinnerStyles = {
    width: 48,
    height: 48,
    alignSelf: "center",
};

export const urlColumnStyles = {
    display: "flex",
    justifyContent: "space-evenly",
};