import gql from "graphql-tag";

export const RequestIdVerification = gql`
    mutation RequestIdVerification($input: RequestIdVerificationInput!) {
        requestIdVerification(input: $input) {
            success
            verificationUrl
        }
    }
`;

export const RetrieveIdVerification = gql`
    mutation RetrieveIdVerification($leadId: UUID!) {
        retrieveIdVerification(leadId: $leadId) {
            success
            data {
                action
                flags
                images {
                    front {
                        croppedImage
                        faceImage
                    }
                }
                fields {
                    dateOfBirth {
                        value
                    }
                    familyName {
                        value
                    }
                    givenName {
                        value
                    }
                    idIssuer {
                        value
                    }
                    idType {
                        value
                    }
                    middleName {
                        value
                    }
                    addressCity {
                        value
                    }
                    addressPostalCode {
                        value
                    }
                    addressCountry {
                        value
                    }
                    addressStreet {
                        value
                    }
                    addressSubdivision {
                        value
                    }
                }
                verificationUrl
            }
        }
    }
`;
