import gql from "graphql-tag";

export const GetLeadsList = gql`
    query LeadsList(
        $filter: LeadFilter
        $after: Cursor
        $before: Cursor
        $limit: Int
    ) {
        leads(
            filter: $filter
            first: $limit
            before: $before
            after: $after
            orderBy: CREATED_AT_DESC
        ) {
            edges {
                node {
                    id
                    firstName
                    lastName
                    phoneNumber
                    emailAddress
                    city
                    createdAt
                    read
                    postalCode
                    provinceCode
                    sourceUrl

                    company {
                        name
                        groupByPrimaryGroup {
                            id
                            cognitoName
                        }
                    }

                    leadJourneys {
                        nodes {
                            stage
                            product {
                                id
                                name
                            }
                        }
                    }

                    avaApp {
                        id
                        creditScore
                        creditVerified
                        creditRange
                        creditBureaus(first: 1, orderBy: CREATED_AT_DESC) {
                            nodes {
                                id
                                type
                                expired
                                createdAt
                            }
                        }
                    }
                    creditApp {
                        id
                        companyName
                        jobTitle
                        employmentStatus
                        monthlyIncome
                    }
                    tradeApps(orderBy: CREATED_AT_ASC) {
                        nodes {
                            id
                            tradeInValue
                        }
                    }
                    leadCampaign {
                        utmSource
                        utmMedium
                        utmContent
                        utmCampaign
                        s5
                        s4
                        s3
                        s2
                        s1
                        id
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            totalCount
        }
    }
`;

export const GetLead = gql`
    query Lead($id: UUID!) {
        lead(id: $id) {
            id
            emailAddress
            firstName
            lastName
            dateOfBirth
            streetNumber
            street
            city
            provinceCode
            postalCode
            phoneNumber
            emailAddress
            country
            sourceUrl
            createdAt
            read
            comment

            leadCampaign {
                id
                s1
                s2
                s3
                s4
                s5
                utmCampaign
                utmContent
                utmMedium
                utmSource
            }

            creditApp {
                id
                residenceTime
                rentOrOwn
                monthlyHomePayment
                employmentStatus
                incomeTime
                monthlyIncome
                incomeSource
                companyName
                salaryHourly
            }

            avaApp {
                id
                creditScore
                creditVerified
                creditRange
                creditBureaus(
                    first: 1,
                    orderBy: CREATED_AT_DESC
                ) {
                    nodes {
                        id
                        type
                        createdAt
                        expired
                        bureau {
                            expired
                            score {
                                value
                            }
                            inquiries {
                                date
                                inquirer {
                                    name
                                }
                            }
                            trades {
                                # Collapsed
                                creditor {
                                    name
                                }
                                # Collapsed & Expanded
                                dateOpened # Date Opened
                                balanceAmount # Balance
                                paymentTermAmount # Payment Amount

                                # Overview
                                ## Balance
                                ## Date Opened
                                association # Account Type
                                portfolioType {
                                    code
                                    description
                                } # this.code + paymentRate.code = Loan Status
                                paymentRate {
                                    code
                                    description
                                } # portfolioType.code + this.code = Loan Status
                                highCreditAmount # High Credit Amount
                                dateLastActivityOrPayment # Last Activity
                                monthsReviewed # Months Reviewed

                                # Payment Information
                                ## Payment Amount
                                paymentTerm # Payment Term
                                lastSlowPayment # Last Slow Payment

                                # Slow Payment Counters
                                derogatoryCounters {
                                    past30
                                    past60
                                    past90
                                }
                            }
                        }
                    }
                }
            }

            tradeApps(orderBy: CREATED_AT_ASC) {
                nodes {
                    id
                    year
                    make
                    model
                    trim
                    mileage
                    condition
                    minTradeInValue
                    maxTradeInValue
                    tradeInValue
                }
            }

            lenderApps {
                nodes {
                    id
                    inovatecApplicationId
                    inovatecApplicationNumber
                    leadId
                    monthlyHomePayment
                    nodeId
                    yearlyIncome
                    employmentStatus
                    createdAt
                    updatedAt

                    offers
                    preferredOfferId

                    vehicleListing {
                        id
                        lenderAppId
                        listingMsrp
                        listingPrice
                        listingTitle
                        listingUrl
                        listingImageUrl
                        nodeId
                        vehicleCondition
                        vehicleEngine
                        vehicleExteriorColor
                        vehicleInteriorColor
                        vehicleMake
                        vehicleMileage
                        vehicleModel
                        vehicleStatus
                        vehicleStockNumber
                        vehicleTransmission
                        vehicleVin
                        vehicleTrim
                        vehicleYear
                        createdAt
                        updatedAt
                    }
                }
            }
            
            retrieveIdVerification {
                action
                createdAt
                customerUid
                dashboardUrl
                duplicates {
                    customerUid
                    transactionId
                    userId
                }
                entity
                fields {
                    dateOfBirth {
                        value
                    }
                    familyName {
                        value
                    }
                    givenName {
                        value
                    }
                    idIssuer {
                        value
                    }
                    idType {
                        value
                    }
                    middleName {
                        value
                    }
                }
                flags
                id
                images {
                    front {
                        croppedImage
                        faceImage
                        fullImage
                    }
                liveness
                }
            }
        }
    }
`;

export const GetLeadCompany = gql`
    query LeadCompany($id: UUID!) {
        lead(id: $id) {
            id
            company {
                id
                rawMeta
                groupByPrimaryGroup {
                    id
                    cognitoName
                }
            }
        }
    }
`;

export const UpdateReadStatus = gql`
    mutation UpdateReadStatus($id: UUID!) {
        updateLead(input: {
            id: $id
            patch: {
                read: true
            }
        }) {
            lead {
                id
                read
            }
        }
    }
`;

export const UpdateLeadComment = gql`
    mutation UpdateLeadComment($id: UUID!, $comments: String!) {
        updateLead(input: {
            id: $id
            patch: {
                comment: $comments
            }
        }) {
            lead {
                id
                comment
            }
        }
    }
`;

export const AdminSubmitLead = gql`
    mutation AdminSubmitLead($companyId: Int!, $messageOverride: String!, $input: LeadInput!, $productType: ProductType!) {
        adminSubmitLead(companyId: $companyId, messageOverride: $messageOverride, input: $input, productType: $productType) {
            leadId
            success
            deduplicated
        }
    }
`;

export const GetLeadsByStage = gql`
    query GetLeadsByStage(
        $filter: LeadFilter
        $condition: LeadCondition
        $offset: Int, 
        $first: Int,
        $orderBy: [LeadsOrderBy!]
    ) {
        leads(
            filter: $filter
            condition: $condition
            first: $first
            offset: $offset
            orderBy: $orderBy
        ) {
            nodes {
                id
                phoneNumber
                city
                firstName
                lastName
                dateOfBirth
                street
                streetNumber
                provinceCode
                country
                postalCode
                retrieveIdVerification {
                    action
                    flags
                    fields { idIssuer { value } }
                    verificationUrl
                }
            }
            totalCount
        }
    }
`;